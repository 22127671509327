import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';
import { max } from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 80,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      },
      '&.MuiInputLabel-outlined .MuiInputLabel-shrink': {
        fontWeight: 'bold'
      },
      '&.MuiInputLabel': {
        color: 'black'
      },
      '& label.Mui-focused': {
        color: 'black'
      }
    }
  },
  goalEntryFields: {},
  sections: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  auditContainer: {
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  title: {
    width: '70%',
    '& .MuiFormControl-root': {
      width: '90%'
    },
    '& .MuiInputBase-input': {
      color: globalTheme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1.4rem'
    },
    '& .MuiInput-underline:after': {
      borderColor: globalTheme.palette.secondary.main
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: globalTheme.palette.primary.main
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
      width: '100%',
      '& .MuiFormControl-root': {
        width: '100%'
      }
    }
  },
  goalFor: {
    width: '30%',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  dateContainer: {
    justifyContent: 'space-between',
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  datePicker: {
    width: '30%',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  date: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px'
    }
  },
  selectContainer: {
    justifyContent: 'space-between',
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  dropdown: {
    width: '19%',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  category: {
    '& .MuiSelect-root': {
      padding: '12px 14px'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px'
    }
  },
  status: {
    '& .MuiSelect-root': {
      padding: '12px 14px'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px'
    }
  },
  track: {
    '& .MuiSelect-root': {
      display: 'flex',
      padding: '12px 14px'
    },
    '& .MuiListItemIcon-root': {
      alignItems: 'center'
    }
  },
  descriptionContainer: {
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '25px'
    }
  },
  description: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '91%',
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      paddingRight: '3px'
    },

    [theme.breakpoints.down('sm')]: {
      backgroundColor: globalTheme.palette.neutral.dark,
      justifyContent: 'unset',
      height: 76,
      width: '100%',
      '& .MuiButtonBase-root': {
        borderRadius: 0,
        textAlign: 'center',
        width: '50%'
      },
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  leftBtnGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '48%',
      backgroundColor: globalTheme.palette.neutral.dark,
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  rightBtnGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '52%',
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  button: {
    margin: `${theme.spacing(5)}px ${theme.spacing(1)}px`,
    padding: theme.spacing(1.75),
    color: globalTheme.palette.neutral.contrast,
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  cancelBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '53%'
      }
    }
  },
  shareBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '47%'
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  saveBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '53%'
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  subtitle: {
    color: globalTheme.palette.primary.main,
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      paddingTop: 30
    }
  },
  actionLink: {
    color: globalTheme.palette.primary.main,
    marginTop: 28,
    fontSize: 20,
    fontWeight: 'bold'
  },
  actionLinkContainer: {
    display: 'flex'
  },
  actionIcon: {
    color: globalTheme.palette.primary.main,
    marginTop: 29,
    marginRight: 5,
    fontSize: 23,
    '@media print': {
      display: 'none'
    }
  },
  actionLinkSection: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  hidden: {
    display: 'none'
  },
  dropdownSectionTitle: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  dropdownDescriptionSectionWidth: {
    maxWidth: '300px',
    wordWrap: 'break-word'
  },
  competencyUrlStyles: {
    marginTop: '5px'
  }
}));
export default useStyles;
